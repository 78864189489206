import api from '../../utils/api'

// 获取详情
export async function pay_detail (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         total_price: 2500,
  //         doctor_name: '刘欢欢'
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/inquiries/orders/doctors/${payload}/prepare`)
}



export async function to_pay (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {

  //       }
  //     })
  //   }, 500)
  // })
  return api.post(`/api/v1/mp/inquiries/orders/doctors/${payload}`)
}
