<template>
  <div class="g_page_box page_box">
    <div class="g_main_content main_content">
      <g-loading :loading="loading" />
      <div class="surplus_time">
        支付剩余时间 <van-count-down class="time" :time="time * 1000" @finish="finish" />
      </div>
      <div class="current_price"><label>¥</label>{{format_money(data.total_price)}}</div>
      <div class="all_price">总金额: ¥{{format_money(data.total_price )}}</div>
      <div class="name">
        <van-icon class="shop" name="shopping-cart" />
        {{data.doctor_name}}医生在线问诊费用
      </div>

      <div class="card_box">
        <div class="way">请选择支付方式</div>
        <div class="card">
          <img src="../../assets/img/yibao.png" class="icon" width="25" height="14">
          <div class="content">
            <div class="title">医保卡</div>
            <div class="price">社保支付金额 ￥{{format_money(data.total_price)}}</div>
          </div>
          <van-checkbox class="radio" v-model="yibao_checked" />
        </div>
      </div>
    </div>
    <div class="bottom_box">
      支付即代表同意<span>《罗湖人民医院互联网医院线上问诊告知书》</span>
      <div class="btn" @click="pay">支付</div>
    </div>
  </div>
</template>

<script>
import { pay_detail, to_pay } from './service'
import { format_money } from '../../utils/format'

export default {
  data () {
    return {
      loading: false,
      id: this.$route.query.id,
      yibao_checked: true,
      data: {},
      time: 24*60*60
    }
  },
  computed: {
  },
  components: {

  },
  created () {
    this.get_detail()
  },
  methods: {
    format_money,
    async get_detail () {
      this.loading = true
      try {
        const {data} = await pay_detail(this.id)
        this.data = data
      } finally {
        this.loading = false
      }
    },
    finish () {
      this.$router.replace({
        path: '/online-inquiry-detail',
        query: {
          id: this.id
        }
      })
    },
    // 支付
    async pay () {
      this.loading = true
      try {
        await to_pay(this.id)
        this.$notify({ type: 'success', message: '支付成功！' })
        this.$router.replace({ path: '/online-chat', query: {id:this.data.sdk_user_id,action_first: 1} })
      } finally {
        this.loading = false
      }
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page_box {
  background: #F7F7F7;
}
.main_content {
  padding: 0 16px;
}
.steps {
  width: 90%;
  margin: 27px auto 0;
}
.surplus_time {
  font-size: 12px;
  line-height: 17px;
  color: #707070;
  margin-top: 33px;
  text-align: center;
  & .time {
    display: inline;
    color: #707070;
    font-size: 12px;
  }
}
.current_price {
  font-size: 30px;
  font-weight: bold;
  line-height: 36px;
  color: #000000;
  margin-top: 16px;
  text-align: center;
  & label {
    font-size: 14px;
  }
}
.all_price {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-top: 5px;
  text-align: center;

}
.name {
  padding: 70px 0 18px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #707070;
  display: flex;
  align-items: center;
  & .shop {
    font-size: 18px;
    color:rgba(0, 136, 255, 1);
    padding-right: 16px;
  }
}
.card_box {
  padding: 15px 16px;
  background: #FFFFFF;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.02);
  opacity: 1;
  border-radius: 12px;
  & .intro {
    font-size: 14px;
    line-height: 20px;
    color: #242938;
    opacity: 0.38;
    padding: 14px 0 6px 19px;
  }
}
.card {
  display: flex;
  & .icon {
    margin-right: 10px;
    transform: translateY(3px);
  }
  & .content {
    flex: 1;
    & .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #242938;
    }
    & .price {
      font-size: 12px;
      line-height: 17px;
      color: #242938;
      margin-top: 2px;
    }
  }
}
.way {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(36, 41, 56, 0.38);
  padding-bottom: 19px;
}
.bottom_box {
  padding: 24px 13px;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(95, 95, 95, 1);
  & >span {
    color: rgba(0, 136, 255, 1);
  }
  & .btn {
    margin-top: 10px;
    width: 100%;
    background: #0088FF;
    height: 36px;
    line-height: 36px;
    background: #1890FF;
    border-radius: 18px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    &.disabled {
      background: #ccc;
    }
  }
}
</style>
